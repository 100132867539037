* {

  padding: 0;
  margin: 0;
  box-sizing: border-box;

}


.row1 {
  --bs-gutter-x: 0rem;
  /* Default horizontal gutter */

}

header {
  background-color: #0b8261;
  width: 100%;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* index.css */

/* NAV */

nav {
  width: 100%;
}

.nav-link {

  color: #f9f9f9;
  /* Link color */
  text-decoration: none;
  /* Remove underline */
  margin: 10px;
  font-family: "Lato", sans-serif;
  /* Add margin between links */
}

/* Nav Dropdown item */
.navItem:focus {
  color: #282c34;
  background-color: white;
}



/* LOGO */

.logo {
  padding: 10px;
  padding-left: 10px;
  max-width: 100%;
}


/* footer */
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure the wrapper fills the entire viewport height */
}

.content {
  flex: 1;
  /* Allow content to grow to fill remaining space */
}

.footer-container {
  background: #0B8261;
  color: #FFFFFF;
  width: 100%;
  flex-shrink: 0;
  font-family: "Lato", sans-serif;
  font-size: 1em;
}



.footer-white-left-side {
  display: flex;
  justify-content: start;
  align-items: start;
  background: #FFFFFF;
  padding: 20px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list li a {
  font-weight: bold;
  color: #3E2B2B;
  text-decoration: none;

}

.footer-list li {
  font-weight: bold;
  color: #3E2B2B;
  margin: 5px;
}

.footer-green-right-side {
  background: #0B8261;
  padding: 20px;
}

.small {
  font-family: "Lato", sans-serif;
  font-weight: 700;
  color: #FFFFFF;
}

.footer-green-right-side p {
  font-family: "Lato", sans-serif;
  display: block;

  line-height: 10px;
  color: #FFFFFF;
}

.texts {
  font-size: 0.8em;
}

.footer-green-right-side img {
  padding: 20px;
}

.text-center {
  text-align: center;
}


/* HOME */




.soups {
  margin-left: 1em;
}



/* HOME CLASSES */

.ingD:hover {
  background-color: #D58F29;

  /* Change background color on hover */
  transform: scale(1.05);
  /* Increase size on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add shadow on hover */
}

.dish {
  max-width: 60%;

}

.container {
  max-width: 100%;
}



/* COVER IMAGE */
.banner-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.banner-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}



/* INGREDIENTS STYLING */


.spec {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 30%;
  border-radius: 10px;
  background-color: #90D5AC;
  margin-bottom: 30px;
  padding: 10px;
  color: #282c34;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  /* Add transition for smooth effect */
}

.spec:hover {
  background-color: #D58F29;
  /* Change background color on hover */
  transform: scale(1.05);
  /* Increase size on hover */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add shadow on hover */
}


.spec1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 30%;
  border-radius: 10px;
  margin-bottom: 30px;
}

.spec2 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 15%;
  border-radius: 10px;
  margin-bottom: 30px;
}

/* H1 STYLING */
.head {

  font-size: 3.5em;
  font-family: "Oswald", sans-serif;
  color: #0B8261;
}

.heads {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 50%;
  font-weight: 600;
  padding: 1em;
  font-size: 1.3em;
  font-family: "Oswald", sans-serif;
  background-color: #FFB600;
  border-radius: 10px;
  color: #3E2B2F;

}

/* BUTTON STYLING */

.btn {
  text-decoration: none;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #0B8261;
  color: rgb(251, 251, 251);
  padding: .5em 1.2em;
  border: none;
  font-size: 1.2em;
  border-radius: 5px;
  font-family: "Oswald", sans-serif;
  transition: all 0.3s ease;
}

.arrow {
  margin-bottom: 4px;
}

.btn:hover {
  background-color: #D58F29;
  color: black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}



.spec h1 {
  font-size: 1.2em;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: #3E2B2F;
}

.spec p {
  font-size: 1em;
  font-family: "Lato", sans-serif;
  font-weight: 600;
  color: #3E2B2F;

}

/* DISH CLASS STYLING */

.ing {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 30%;
  background-color: #90D5AC;
  margin-bottom: 30px;
  padding: 10px;
  color: #282c34;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  /* Add transition for smooth effect */
}


.ing h1 {
  font-size: 1.3em;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: #3E2B2F;
}



.ingD {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 10px;
  max-width: 40%;
  background-color: #90D5AC;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);

  color: #282c34;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  /* Add transition for smooth effect */
}


.ingD h1 {
  font-size: 1.7em;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  color: #3E2B2F;
}


/* DISH IMAGES */

.imDs {
  max-width: 90%;
}

/* CUSTOM BAR AND NAV */
.custom-toggler .navbar-toggler-icon {
  border: none;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba(255, 255, 255, 1)" stroke-width="5" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E');
}

.custom-toggler {
  border: none;
}

.custom-toggler:focus {
  border: none;
}


.spec .steps {
  color: #3E2B2F;
  font-size: 1em;
  background-color: #FFFFFF;
  max-width: 95%;
  padding: 7px;
  border-radius: 10px;
}


.dec {
  text-decoration: none;
}



.no-hover:hover {

  /* Disable pointer events */
  cursor: default;
  /* Set default cursor */
}

.durhamcollege {
  margin-right: 10px;
}

/* mobile */
@media (max-width: 768px) {
  .logo {
    max-width: 40%;
    /* Increase size on smaller screens */
  }




  .heads {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    text-align: center;
    font-weight: 600;
    padding: 10px;
    font-size: 1.1em;
    font-family: "Oswald", sans-serif;
  }

  .spec .steps {
    color: #3E2B2F;
    font-size: 1em;
    background-color: #FFFFFF;
    max-width: 80%;
    padding: 10px;
    border-radius: 10px;
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
  }





  .spec {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 10px;
    background-color: #90D5AC;
    margin-bottom: 30px;
  }

  .ing {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 10px;
    background-color: #90D5AC;
    margin-bottom: 30px;
  }

  .ingD {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 0 auto;
    border-radius: 10px;
    max-width: 60%;
    background-color: #90D5AC;
    padding-bottom: 10px;
    color: #282c34;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    /* Add transition for smooth effect */
  }

  .ingD h1 {
    font-size: 1.1em;
    margin-left: 0;
  }

  .imDs {
    max-width: 90%;
  }

  .spec h1 {
    text-align: center;
    font-size: 1em;
  }



  .head {
    font-size: 2.2em;
    font-family: "Oswald", sans-serif;
    color: #0B8261;
    text-align: center;
  }

  .mob {
    text-align: center;
  }

  .Soup {
    max-width: 40%;
  }



}

@media screen and (max-width: 1024px) {
  .nav-link {
    font-size: 0.8em;
    /* Adjust font size for tablet-sized screens */
    margin: 7px;
    /* Adjust margin for tablet-sized screens */
  }

  .spec h1 {
    font-size: 1.1em;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
  }

}